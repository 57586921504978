@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Gilroy/gilroy-regular.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Gilroy/gilroy-medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Gilroy/gilroy-semibold.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Gilroy/gilroy-bold.woff') format('woff');
}

* { margin: 0; padding: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body {
  background: linear-gradient(148.18deg, #EDEFF6 0%, #EDF6F5 17.19%, #EDF6F5 44.79%, #F6F6ED 100%);

  &__mobile {
    overflow: hidden;
    background: linear-gradient(148.18deg, #EDEFF6 0%, #EDF6F5 16.67%, #F6F6ED 100%);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  height: 100vh;
  margin: 0 auto;
  padding: 0 100px;
}

.section-about {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  margin-top: 80px;
  padding: 0 100px;
  background: url("../public/images/about-background.png") no-repeat;
  background-size: 100% 409px;

  &--mobile {
    display: flex;
    align-items: center;
    height: calc(100vh - 55px);
    margin-top: 60px;
    padding: 0 20px;
    background: url("../public/images/about-background-mobile.png") no-repeat;
    background-size: 100%;
  }
}

.section-targets {
  position: relative;
  padding: 48px 100px;

  &--mobile {
    padding: 32px 20px 16px 20px;
  }
}

.section-articles {
  position: relative;
  padding: 48px 100px;
  background-color: #fff;

  &--mobile {
    padding: 32px 20px 24px 20px;
    background-color: #fff;
  }
}

.section-objects {
  padding: 48px 100px;
  background-color: #fff;

  &--mobile {
    padding: 32px 20px 0 20px;
    background-color: #fff;
  }
}

.section-gallery {
  padding: 48px 100px;
  background-color: #fff;

  &--mobile {
    padding: 24px 20px;
    background-color: #fff;
  }
}

.footer {
  padding: 24px 100px;
  background-color: #000;

  &--mobile {
    padding: 24px 16px;
    background-color: #000;
  }
}
