.nav {
  position: relative;
  width: 19px;
  height: 12px;

  span {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 1;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 6px;
    }

    &:nth-child(3) {
      top: 6px;
      opacity: 0;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }

  &.open span:nth-child(1) {
    opacity: 0;
  }

  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
    opacity: 1;
  }

  &.open span:nth-child(4) {
    opacity: 0;
  }
}